import { Link, Text, View } from "@react-pdf/renderer";
import React from "react";

export default {
  LANGUAGE: "Bahasa",
  KOCEK_OUT_ONLINE_ORDER: "KOCEK Pesanan Atas Talian",
  SERVICE_FEE_CALCULATOR: "Kalkulator caj perkhidmatan",
  INSERT_KOCEK_OUT_AMOUNT: "Masukkan amaun KOCEK Out",
  SUBJECT_TO_STOCK_AVAILABILITY: "Tertakluk kepada ketersediaan stok",
  PAYMENT_AMOUNT: "Jumlah pembayaran",
  SERVICE_FEE_CALCULATOR_TITLE: "Kira jumlah pembayaran dengan kadar caj perkhidmatan kami",
  TERMS_AND_CONDITIONS: "Terma & syarat",
  COIN_WITHDRAWAL_BOOKING: "Coin Withdrawal Booking",
  MERCHANT_SUBTITLE: "...sebab setiap sen bernilai",
  MERCHANT_TITLE: "LANGKAH 1: PILIK LOKASI KIOSK",
  NUMBER_OF_KIOSK: (number) => {
    return `${number} lokasi kiosk seluruh negara`;
  },
  STATES: "Negeri",
  DISTANCE: "Jarak",
  OPENING_HOUR: "Waktu operasi",
  FILTER: "Tapis",
  FILTER_BY: "Tapis mengikut",
  SEARCH_STATE: "Cari negeri",
  NEXT: "SETERUSNYA",
  PREVIOUS: "SEBELUMNYA",
  OPEN: "Buka",
  CLOSE: "Tutup",
  PLACE_ORDER: "BUAT PESANAN",
  CONFIRM: "SAHKAN PESANAN",
  SUBTOTAL: "Jumlah kecil",
  ORDER_DETAILS: "Butiran pesanan",
  PRODUCT_LISTING_TITLE: "LANGKAH 2: PESANAN DUIT SYILING",
  BUSINESS_TITLE: "LANGKAH 3: BUTIRAN PERNIAGAAN",
  OPTIONAL: "Optional",
  NO_RECORD_IN_ORDER_DETAILS: "Tiada rekod. Mulakan dengan mengisi pesanan anda.",
  REACH_LIMIT: "Limit reached",
  SERVICE_FEE: "Tertakluk kepada caj perkhidmatan",
  COIN_DESCRIPTION: "Senarai duit syiling",
  QUANTITY: "Kuantiti paket",
  PACK: "paket",
  OLD_COIN: "Siri 1/Siri 2",
  NEW_COIN: "Siri 3",
  ITEM: "item",
  SOMETHING_WENT_WRONG: "Something went wrong",
  FETCH_FAIL: "Sorry, we can’t process your request at the moment. Please try again later or click ‘Retry’.",
  RETRY: "CUBA SEMULA",
  ENTER_EMAIL: "Masukkan alamat emel anda",
  LOGIN_TITLE: "Mari berhubung dan dapatkan maklumat terkini tentang pesanan anda",
  CONTINUE: "TERUSKAN",
  EMAIL_PLACEHOLDER: "contoh@email.com",
  SIGN_UP: "Daftar akaun",
  SIGN_UP_TITLE: "Anda selangkah lebih dekat untuk melengkapkan pesanan anda! ",
  SIGN_UP_TERM_AND_CONDITION: "Dengan menekan butang ‘Teruskan’ di bawah, anda bersetuju dengan",
  SIGN_UP_TERM_AND_CONDITION_1: "Terma & Syarat",
  SIGN_UP_TERM_AND_CONDITION_2: "kami",
  NAME: "Nama",
  EMAIL_VALIDATION: "Sila masukkan alamat emel yang sah",
  PHONE_NUMBER_VALIDATION: "Sila masukkan nombor telefon yang sah",
  PHONE_NUMBER: "Nombor telefon",
  CHECK_EMAIL: "Semak emel anda",
  CONTINUE_AS_GUEST: "Teruskan sebagai tetamu",
  ALREADY_HAVE_AN_ACCOUNT: "Sudah mempunyai akaun?",
  CONTINUE_WITH_EMAIL: "Teruskan dengan emel",
  VERIFICATION_TITLE: "Sila masukkan kod 6-digit yang telah dihantar ke emel anda untuk pengesahan",
  RESEND_VERIFICATION_CODE: (number) => {
    return `Hantar semula kod dalam ${number} saat`;
  },
  NO_VERIFICATION_CODE: "Tidak mendapat kod? Hantar semula ke emel",
  BUSINESS_DETAILS_TITLE2: "Menggunakan duit syiling untuk perniagaan anda? Kongsikan dengan kami",
  CHECKOUT: "Semak keluar",
  BOOKING_DETAILS: "BUTIRAN PENGAMBILAN PESANAN",
  BOOTH_LOCATION: "Lokasi kiosk",
  COIN_ORDER_SUMMARY: "RINGKASAN PESANAN",
  AMOUNT: "Amaun",
  TOTAL_PAYMENT: "Jumlah perlu dibayar",
  TOTAL_PAYMENT_PAID: "Jumlah bayaran",
  PAY_NOW: "BAYAR SEKARANG",
  PAYMENT_METHOD: "KAEDAH PEMBAYARAN",
  SELECT_PAYMENT_METHOD: "Sila pilih kaedah pembayaran",
  PERSONAL_DETAILS: "BUTIRAN PERIBADI",
  EDIT: "EDIT",
  NAME_AND_PHONE: "Nama & Nombor telefon",
  EMAIL: "Alamat e-mel",
  BUSINESS_DETAILS: "BUTIRAN PERNIAGAAN",
  BUSINESS_DETAILS_TITLE: "Withdrawing coins for your business use? Tell us more!",
  BUSINESS_NAME: "Nama perniagaan",
  BUSINESS_NAME_PLACEHOLDER: "Masukkan nama perniagaan",
  BUSINESS_INDUSTRY: "Sektor perniagaan",
  BUSINESS_INDUSTRY_PLACEHOLDER: "Pilih sektor perniagaan",
  SKIP_THIS_STEP: "Langkau ke semak keluar",
  PAYMENT_DETAILS: "BUTIRAN PEMBAYARAN",
  TOTAL_WITHDRAWAL_AMOUNT: "Amaun KOCEK Out",
  SERVICE_FEE_PERCENT: "Caj Perkhidmatan",
  ADD_MORE_ITEMS: "Tambah item",
  PICKUP_AMOUNT: "Pickup amount",
  SST: "Inclusive 6% SST",
  TNC_AGREE: "Dengan menekan butang ‘Teruskan’ di bawah, anda bersetuju dengan Terma & Syarat kami.",
  AGREE_DETAILS: "Saya telah membaca dan bersetuju dengan jumlah dan lokasi pengambilan pesanan.",
  AGREE_TNC: ({ openModal }) => (
    <p className="subtitle mb-0">
      By clicking the ‘Pay now’ button below, you agree with our{" "}
      <button
        type="button"
        style={{
          color: "#3FBAFF",
          border: "none",
          backgroundColor: "transparent",
        }}
        onClick={openModal}
      >
        Terms & Conditions.
      </button>
    </p>
  ),
  SAVE_CHANGES: "SIMPAN",
  COIN_ORDER: "Syiling Pesanan",
  TERMS_AND_CONDITIION: "Terms & Conditions",
  CURRENCY: "Mata wang",
  CURRENCY_CONTENT: "Perkhidmatan pertukaran hanya tersedia untuk mata wang Malaysia sahaja.",
  MINIMUM_AMOUNT: "Jumlah Minimum",
  MINIMUM_AMOUNT_CONTENT:
    "Duit syiling yang disediakan oleh KOCEK akan dibungkus dengan jumlah minimum RM50.00 setiap pek.",
  COIN_SPECIFICATION: "Spesifikasi duit syiling",
  COIN_SPECIFICATION_CONTENT:
    "Selaras dengan Garis Panduan Bank Negara Malaysia mengenai Standard Kualiti untuk Mata Wang Malaysia, perkhidmatan pertukaran hanya disediakan untuk mata wang Malaysia sahaja dan hanya untuk mata wang dan syiling yang berada dalam keadaan baik dan bebas daripada kecacatan.",
  MINIMUM_EXCHANGE: "Pertukaran minimum",
  MINIMUM_EXCHANGE_CONTENT:
    "Jumlah minimum untuk pertukaran duit syiling bagi setiap individu ialah RM50.00 setiap transaksi",
  INFORMATION_SHARING: "Information Sharing",
  INFORMATION_SHARING_CONTENT: ({ color }) => (
    <p className="content">
      The minimum amount for coin exchange for each individual shall be RM100.00 per transaction and a maximum of{" "}
      <span style={{ color }}>RM1,000.00</span> per transaction, per day.
    </p>
  ),
  TNC_SERVICE_FEE: "Caj perkhidmatan",
  TNC_SERVICE_FEE_CONTENT:
    "Caj perkhidmatan sebanyak 5% daripada jumlah syiling yang diurus niagakan akan dikenakan kepada pelanggan.",
  ORDER_COLLECTION: "Pengambilan pesanan",
  ORDER_COLLECTION_LOCATION: "Lokasi pengambilan pesanan",
  ORDER_COLLECTION_CONTENT:
    "Untuk tujuan pengesahan, pelanggan atau wakil mereka dikehendaki mengemukakan resit pembayaran untuk disemak dan disahkan oleh pasukan KOCEK sebelum pesanan boleh diambil.",
  COLLECTION_OF_ORDER: "Pengambilan Pesanan",
  COLLECTION_OF_ORDER_CONTENT:
    "Sebarang pesanan yang masih tidak diambil selepas tempoh Tujuh (7) hari dari tarikh transaksi akan dianggap batal dan tidak sah, dan bayaran yang dibuat untuk pasanan yang tidak diambil itu akan dikembalikan ke akaun bank Pelanggan.",
  EXEMPTION: "Pengecualian",
  EXEMPTION_CONTENT:
    "KOCEK tidak akan bertanggungjawab terhadap sebarang kehilangan atau kesulitan yang disebabkan oleh maklumat yang salah atau tidak tepat oleh pelanggan semasa pesanan duit syiling dibuat. Adalah menjadi tanggungjawab pelanggan untuk memastikan semua maklumat yang diberikan, termasuk tetapi tidak terhad kepada butiran peribadi dan arahan transaksi adalah tepat.",
  INSPECTION: "Pemeriksaan pesanan",
  INSPECTION_CONTENT:
    "Pelanggan berhak untuk memeriksa duit syiling dan mengesahkan jumlah duit syiling yang ditukar di gerai pertukaran.",
  CONFIDENTIALITY: "Kerahsiaan",
  CONFIDENTIALITY_CONTENT:
    "Maklumat peribadi yang diberikan oleh pelanggan, termasuk nama penuh, alamat emel dan nombor telefon, akan digunakan semata-mata untuk tujuan memproses pesanan dan menyediakan maklumat terkini berkenaan pesanan. Kami tidak akan berkongsi, menjual atau mendedahkan maklumat pelanggan kepada pihak ketiga, kecuali seperti yang dikehendaki oleh undang-undang atau dengan persetujuan jelas pelanggan.",
  FORCE_MAJUERE: "Keadaan tidak dijangka",
  FORCE_MAJUERE_CONTENT:
    "KOCEK tidak akan bertanggungjawab untuk sebarang kehilangan, kerosakan atau kesulitan yang disebabkan oleh keadaan di luar kawalan kami, termasuk tetapi tidak terhad kepada bencana alam, kegagalan teknikal atau sebarang kejadian force majeure yang lain.",
  UPDATE_AND_MODIFICATION: "Maklumat terkini & Pengubahsuaian",
  UPDATE_AND_MODIFICATION_CONTENT:
    "Terma dan syarat yang digariskan di sini adalah tertakluk kepada maklumat terkini, pengubahsuaian atau semakan mengikut budi bicara KOCEK. Pelanggan akan dimaklumkan tentang sebarang perubahan melalui emel, web atau cara lain yang sesuai. Adalah menjadi tanggungjawab pelanggan untuk menyemak terma dan syarat yang dikemas kini secara berkala. Penggunaan berterusan perkhidmatan pengeluaran duit syiling dan deposit duit syiling KOCEK selepas pengeluaran terma dan syarat yang dikemas kini membayangkan penerimaan pelanggan terhadap terma yang disemak.",
  ORDER_VERIFICATION: "Pengesahan Pesanan",
  ORDER_VERIFICATION_CONTENT: () => (
    <ol style={{ paddingLeft: 16, marginBottom: 0 }}>
      <li className="content">
        Pelanggan atau wakil mereka perlu mengemukakan resit yang disediakan untuk duit syiling yang diurusniagakan
        kepada pekerja KOCEK kami untuk tujuan pengesahan.
      </li>
      <li className="content">
        Sebarang isu yang timbul berkaitan dengan keadaan duit syiling atau jumlah duit syiling yang ditukar akan
        ditangani segera di gerai.
      </li>
    </ol>
  ),
  PERSONAL_INFORMATION: "Personal Information",
  PERSONAL_INFORMATION_CONTENT: () => (
    <>
      <p className="content">
        The personal information provided by customers, including full name, email address, and phone number, will be
        used solely for the purpose of processing orders and providing updates.
      </p>
      <p className="mt-3 content">
        We will not share, sell, or disclose customer information to third parties, except as required by law or with
        the customer's explicit consent.
      </p>
    </>
  ),
  INFORMATION_COLLECTION_AND_USE: "Pengumpulan dan Penggunaan Maklumat",
  INFORMATION_COLLECTION_AND_USE_CONTENT:
    "Maklumat peribadi yang diberikan oleh pelanggan, termasuk nama penuh, alamat emel dan nombor telefon, akan digunakan semata-mata untuk tujuan memproses pesanan dan menyediakan maklumat terkini berkenaan pesanan. Kami tidak akan berkongsi, menjual atau mendedahkan maklumat pelanggan kepada pihak ketiga, kecuali seperti yang dikehendaki oleh undang-undang atau dengan persetujuan jelas pelanggan.",
  CUSTOMER_ACKNOWLEDGEMENT: "Pengakuan pelanggan",
  CUSTOMER_ACKNOWLEDGEMENT_CONTENT:
    "Pelanggan mempunyai hak untuk memeriksa duit syiling dan mengesahkan jumlah yang ditukar di kiosk pertukaran. Sebarang isu yang timbul berkaitan keadaan duit syiling atau jumlah duit syiling yang ditukar hanya diambil kira sepanjang proses pengambilan pesanan di kiosk. Jika tidak, pelanggan mengakui bahawa jumlah syiling yang ditukar adalah betul dan syiling berada dalam keadaan baik.",
  LIMITATION_OF_LIABILITY: "Had liabiliti",
  LIMITATION_OF_LIABILITY_CONTENT:
    "KOCEK tidak akan bertanggungjawab atas sebarang kehilangan, kerosakan atau kesulitan yang disebabkan oleh keadaan di luar kawalan kami, termasuk tetapi tidak terhad kepada bencana alam, kegagalan teknikal atau sebarang kejadian force majeure yang lain.",
  UNCOLLECTED_ORDERS: "Pesanan yang tidak diambil",
  UNCOLLECTED_ORDERS_CONTENT:
    "Mana-mana pesanan yang masih tidak diambil selepas tempoh Tujuh (7) hari dari tarikh transaksi akan dianggap batal dan tidak sah, dan bayaran yang dibuat untuk pesanan yang tidak dikutip akan dikembalikan ke akaun bank Pelanggan.",
  BACK_TO_HOME_PAGE: "Kembali ke KOCEK Out Pesanan Atas Talian",
  PAYMENT_METHOD_TITLE: "Kaedah Pembayaran",
  ONLINE_BANKING: "Perbankan Atas Talian",
  CREDIT_CARD: "Kad Kredit/ Debit",
  AMERICAN_EXPRESS: "American Express",
  ONLINE_PAYMENT: "Pembayaran atas talian",
  MANUAL_PAYMENT: "Pembayaran manual",
  KOCEK_TELLER_MACHINE: "KOCEK Teller Machine",
  PAYMENT_INSTRUCTION: "Cara pembayaran akan diberikan pada halaman seterusnya",
  CIMB: "Pay in cash at CDM machine",
  CIMB_PS: "(at CIMB branches only)",
  JOMPAY: "Kocek Teller Machine - ATM JomPAY",
  JOMPAY_TITLE: "Bayar di mesin ATM dengan JomPAY",
  JOMPAY_PS: "(bank yang sama dengan kad ATM anda)",
  CIMB_INSTRUCTIONS: (amount) => (
    <ol className="list-container">
      <li className="list">Go to the nearest CIMB CDM machine. Make sure the machine has a receipt.</li>
      <li className="list">Select bank/ credit card account.</li>
      <li className="list">
        Enter the following account number: <div className="highlighted-text">99001 0000 00102</div>
      </li>
      <li className="list">
        Make sure the account holder name is <span className="fst-italic">KOCEK</span> before starting the payment.
      </li>
      <li className="list">Insert money into the CDM machine.</li>
      <li className="list">
        Make sure the amount shown on the screen is: <div className="highlighted-text">{`RM${amount}`}</div>
      </li>
      <li className="list">
        Select <span className="fst-italic">Accept</span> if the amount is sufficient.
      </li>
      <li className="list">
        Take a photo of the receipt along with order number and send a WhatsApp to KOCEK Hotline for confirmation:{" "}
        <div className="highlighted-text">(+60)11 56655743</div>
      </li>
      <li className="list">
        An official receipt will be sent to your email/ phone number (WhatsApp) once payment is confirmed.{" "}
      </li>
    </ol>
  ),
  JOMPAY_KTM_TITLE: "Pembayaran manual - ATM JomPAY",
  JOMPAY_KTM_SUBTITLE: "Ikut panduan di bawah untuk membuat pembayaran di mesin ATM menggunakan JomPAY.",
  JOMPAY_LIST_1: "Pergi ke mesin ATM Bank yang sama dengan kad ATM anda",
  JOMPAY_LIST_2: "Masukkan kad ATM dan PIN anda",
  JOMPAY_LIST_3: "Pilih Bill payment",
  JOMPAY_LIST_4: "Pilih JomPAY",
  JOMPAY_LIST_5: "Masukkan kod pengebil berikut:",
  JOMPAY_LIST_6: "Masukkan REF-1:",
  JOMPAY_LIST_7: "Masukkan amaun bayaran:",
  JOMPAY_LIST_8: "Cetak dan simpan resit pembayaran sebagai rujukan",
  JOMPAY_LIST_9: "Resit rasmi akan dihantar ke emel anda setelah pembayaran disahkan",
  JOMPAY_INSTRUCTIONS: (amount) => (
    <ol className="list-container">
      <li className="list">Pergi ke mesin ATM Bank yang sama dengan kad ATM anda</li>
      <li className="list">Masukkan kad ATM dan PIN anda</li>
      <li className="list">Pilih Bill payment</li>
      <li className="list">Pilih JomPAY</li>
      <li className="list">
        Masukkan kod pengebil berikut:
        <div className="highlighted-text">631796</div>
      </li>
      <li className="list">
        Masukkan REF-1:
        <div className="highlighted-text">101306202301</div>
      </li>
      <li className="list">
        Masukkan amaun bayaran:
        <div className="highlighted-text">{`RM${amount}`}</div>
      </li>
      <li className="list">Cetak dan simpan resit pembayaran sebagai rujukan</li>
      <li className="list">Resit rasmi akan dihantar ke emel anda setelah pembayaran disahkan. </li>
    </ol>
  ),
  PAYMENT_TITLE: "Panduan Pembayaran",
  VIEW_ORDER: "SEMAK PESANAN",
  TRANSACTION_FAILED: "Oops! Transaksi gagal",
  TRANSACTION_FAILED_DESCRIPTION: "Jangan risau, pesanan anda masih selamat. Tekan butang ‘Cuba Semula’ di bawah.",
  TRANSACTION_PENDING_DESCRIPTION: `Bayaran anda masih belum selesai. Lengkapkan pembayaran anda sekarang supaya pasukan kami boleh menyediakan pesanan anda\n\nTekan ‘Kembali ke Panduan Pembayaran’ untuk membuat pembayaran.`,
  PENDING_BUTTON: "KEMBALI KE PANDUAN PEMBAYARAN",
  PENDING_PAYMENT: "PEMBAYARAN BELUM SELESAI",
  FAILED: "GAGAL",
  RECEIVED: "DITERIMA",
  TAX_INVOICE_NO: "No. invois cukai",
  TRANSACTION_ID: "ID transaksi",
  WHATS_NEXT: "Tindakan seterusnya",
  ORDER_PROCESSED: "Pesanan anda sedang diproses dan akan siap dalam masa 2 hari.",
  ORDER_INSTRUCTION: () => (
    <ol className="order-instruction-list-container">
      <li className="details-1 ps-2">
        Sila muat turun resit pembayaran sebagai bukti. Kami juga telah menghantar salinan melalui emel.
      </li>
      <li className="details-1 ps-2">Anda akan dimaklumkan melalui eamel apabila pesanan anda sedia untuk diambil.</li>
      <li className="details-1 ps-2">Datang ke kiosk KOCEK pada lokasi dan masa yang dinyatakan.</li>
      <li className="details-1 ps-2">Sediakan resit anda dan jom KOCEK Out!</li>
    </ol>
  ),
  ORDER_INSTRUCTION_PDF: () => (
    <View className="order-instruction-list-container">
      <View style={{ flexDirection: "row", marginBottom: 4, marginTop: 5 }}>
        <Text style={{ marginHorizontal: 8, fontSize: 14 }}>1.</Text>
        <Text style={{ color: "#303030", fontSize: 14, flex: 1 }}>
          Sila muat turun resit pembayaran sebagai bukti. Kami juga telah menghantar salinan melalui emel.
        </Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4, marginTop: 5 }}>
        <Text style={{ marginHorizontal: 8, fontSize: 14 }}>2.</Text>
        <Text style={{ color: "#303030", fontSize: 14, flex: 1 }}>
          Anda akan dimaklumkan melalui eamel apabila pesanan anda sedia untuk diambil.
        </Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4, marginTop: 5 }}>
        <Text style={{ marginHorizontal: 8, fontSize: 14 }}>3.</Text>
        <Text style={{ color: "#303030", fontSize: 14, flex: 1 }}>
          Datang ke kiosk KOCEK pada lokasi dan masa yang dinyatakan.
        </Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4, marginTop: 5 }}>
        <Text style={{ marginHorizontal: 8, fontSize: 14 }}>4.</Text>
        <Text style={{ color: "#303030", fontSize: 14, flex: 1 }}>Sediakan resit anda dan jom KOCEK Out!</Text>
      </View>
    </View>
  ),
  COPYRIGHT: () => (
    <>
      <p className="copyright">KOCEK dikuasai oleh DAPAT VISTA (M) SDN BHD (200001005753 (508358-X))</p>
      <p className="copyright mt-0">
        Untuk sebarang pertanyaan, sila hubungi kami di{" "}
        <a href="mailto:help@mypay.com.my" style={{ color: "#3FBAFF", textDecorationLine: "underline" }}>
          help@mypay.com.my
        </a>{" "}
        | (+60) 3-56260033
      </p>
    </>
  ),
  COPYRIGHT_PDF: () => (
    <>
      <Text
        style={{
          color: "white",
          fontSize: 12,
          textAlign: "center",
          marginTop: 26,
          marginBottom: 10,
        }}
      >
        KOCEK dikuasai oleh DAPAT VISTA (M) SDN BHD (200001005753 (508358-X))
      </Text>
      <Text
        style={{
          color: "white",
          fontSize: 12,
          textAlign: "center",
          marginBottom: 10,
        }}
      >
        Untuk sebarang pertanyaan, sila hubungi kami di{" "}
        <Link src="mailto:help@mypay.com.my" style={{ color: "#3FBAFF", textDecorationLine: "underline" }}>
          help@mypay.com.my
        </Link>{" "}
        | (+60) 3-56260033
      </Text>
    </>
  ),
  DOWNLOAD_PDF_RECEIPT: "MUAT TURUN RESIT PDF",
  NOTE: "Nota",
  DISTANCE_NOTE: "Jarak ditunjukkan adalah anggaran sebagai rujukan",
  kocekIn: {
    WELCOME_BACK_TO_KOCEK: "Welcome back to KOCEK!",
    LOGIN_WITH: "Log masuk dengan MyDigital ID",
    NRIC: "NRIC or Passport number",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm password",
    SIGN_IN: "SIGN IN",
    CREATE_AN_ACCOUNT: "Create an account",
    NOT_KOCEK_MEMBER: "Not a KOCEK member yet",
    NO_SPECIAL_CHARACTERS: "Special characters are invalid eg. +-.!",
    IC_LENGTH: "Sila masukkan IC yang betul",
    SORTING_COINS: "Sorting your coins",
    SORT_TITLE: "Thank you for using KOCEK. If you've given us your coins, relax while we sort them.",
    SORT_TITLE_2:
      "Once done, you'll get a 6-digit code from our staff. Click the 'Insert code' button to view your transaction summary.",
    INSERT_CODE: "INSERT CODE",
    PERSONAL_DETAILS: "PERSONAL DETAILS",
    PASSWORD_SETTING: "PASSWORD SETTING",
    FULL_NAME: "Full name",
    ENTER_FULL_NAME: "Enter full name",
    PHONE_NUMBER: "Phone number",
    EMAIL: "Email address",
    HOME_ADDRESS: "Home address",
    SET_PASSWORD: "SET PASSWORD",
    OCCUPATION_TYPE: "Occupation type",
    OCCUPATION_TYPE_PLACEHOLDER: "Select occupation",
    MOBILE_NUMBER_UNSUITABLE: "Unsuitable mobile number",
    EMAIL_ADDRESS_UNSUITABLE: "Unsuitable email address",
    PASSWORD_UNSUITABLE: "Unsuitable password",
    PASSWORD_DO_NOT_MATCH: "Password do not match",
    MOBILE_NUMBER_EXISTS: "Mobile number already registered",
    EMAIL_ADDRESS_EXISTS: "Email address already registered",
    ID_NUMBER_EXISTS: "ID number already registered",
    PASSWORD_RULE: "Your password must be at least 8 characters long and include at least one number and one symbol.",
    VERIFY_ACCOUNT: "VERIFY ACCOUNT",
    VERIFICATION_TITLE: (email) => (
      <div className="title2 mt-3 text-muted">
        Please key-in the 6-digit code sent to your email {email} for verification
      </div>
    ),
    ACCOUNT_VERIFIED: "You account has been verified",
    ACCOUNT_VERIFIED_TITLE:
      "Thank you for registering your account with KOCEK. Please click the 'Convert coins' button to proceed.",
    CONVERT_COINS: "CONVERT COINS",
    BACK_TO_HOME_PAGE: "Back to homepage",
  },
  KIOSK_OPEN: "Kiosk sedang dibuka",
  STEP_TWO_TITLE: "LANGKAH 2: PILIH PERKHIDMATAN",
  CONVERT_COINS: "Tukar duit syiling - KOCEK In",
  BUY_COINS: "Beli duit syiling - KOCEK Out",
  WHAT_FEE: "Apa caj yang akan dikenakan? Kira caj",
  SERVICE_FEE_DETAILS: "Butiran Caj Perkhidmatan",
  YOUR_SIMPLE_SOLUTION:
    "Penyelesaian mudah untuk menukar duit syiling yang tidak digunakan kepada nilai tunai digital.",
  A_CONVENIENT_SOLUTION:
    "Penyelesaian yang mudah untuk keperluan transaksi perniagaan harian anda. Kami menawarkan perkhidmatan yang mudah untuk membeli duit syiling tanpa sebarang kesulitan",
  FREE_OF_CHARGE: "Percuma",
  CALCULATE_FEE: "Kira Caj",
  INSERT_CODE: "Masukkan kod",
  KEY_IN_CODE: "Sila masukkan kod 6 digit yang disediakan oleh kakitangan untuk melihat ringkasan penukaran syiling",
  SUMMARY_TITLE: "LANGKAH 3: RINGKASAN PENUKARAN SYILING",
  SUMMARY_REVIEW: "Sila semak ringkasan syiling yang ditukar dan caj perkhidmatan yang akan dikenakan",
  KOCEK_IN_AMOUNT: "Jumlah KOCEK In",
  TOTAL_AMOUNT: "Jumlah keseluruhan",
  AMOUNT_TO_BE_TRANSFERED: "Amaun yang akan dipindahkan",
  TERMS_AND_CONDITION_KOCEK_IN:
    "Saya bersetuju dengan semua terma dan syarat transaksi ini, termasuk caj yang dikenakan dan kaedah pembayaran untuk jumlah penukaran saya",
  TOTAL_KOCEK_IN_AMOUNT: "Jumlah KOCEK In",
  VOUCHER_INCENTIVE_AMOUNT: "Jumlah insentif voucher",
  TOTAL_VOUCHER_AMOUNT: "Jumlah voucher",
  VOUCHER_TO_BE_RECEIVED: "Voucher yang akan diterima",
  KIOSK_INFORMATION: "MAKLUMAT KIOSK",
  PAYOUT_DETAILS: "BUTIRAN PEMBAYARAN",
  INFO_CONFIRM: "Saya mengesahkan bahawa butiran yang diberikan adalah tepat.",
  BANK_NAME: "Nama bank",
  ACCOUNT_NUMBER: "Nombor akaun",
  ACCOUNT_HOLDER: "Nama pemegang akaun",
  SELECT_BANK: "Pilih bank",
  ENTER_ACCOUNT_NUMBER: "Masukkan nombor akaun",
  ENTER_ACCOUNT_HOLDER: "Masukkan nama pemegang akaun",
  BANK_DETAILS_WARNING:
    "KOCEK tidak akan bertanggungjawab terhadap sebarang kesilapan dalam butiran akaun bank. Sila pastikan butiran yang diberikan adalah tepat.",
  BANK_DETAILS: "Butiran bank",
  ENTER_YOUR_ACCOUNT_NUMBER: "Masukkan no akaun anda",
  ENTER_YOUR_ACCOUNT_HOLDER: "Masukkan nama pemegang akaun",
  BANK_ACCOUNT_DETAILS: "Butiran akaun bank",
  ADD_BANK_ACCOUNT: "Tambah akaun bank",
  SAVE_BANK_ACCOUNT: "Simpan akaun bank supaya mudah untuk transaksi akan datang dan setuju dengan terma dan syarat",
  UPDATE: "KEMASKINI",
  SAVE: "SIMPAN",
  REMOVE_ACCOUNT: "Buang akaun",
};
