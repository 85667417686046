import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ISTREAM_API_KEY, ISTREAM_BUSINESS_NAME } from "../../../configs/ENV";
import MainLayout from "../../../layouts/MainLayout";
import "./style.css";
import { isValidIDNumber } from "../../../utils";
import Axios from "../../../configs/serviceProvider/Axios";
import API from "../../../configs/API";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { CoinContext } from "../../../configs/context/CoinContextProvider";
import { myDigitalIDRoundLogo } from "../../../images";
import { generateNonce } from "./generateNonce";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [ic, setIC] = useState("");
  const [icError, setIcError] = useState(null);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setUserToken, setMdidSession, mdidSession } = useContext(CoinContext);
  const [nonce, setNonce] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const playStoreURL = "https://play.google.com/store/apps/details?id=my.mimos.signetclient";
  const appStoreURL = "https://apps.apple.com/my/app/mydigital-id/id1435289143";

  useEffect(() => {
    if (mdidSession && Object.keys(mdidSession).length > 0) handleMdidSessionLogin();
  }, [mdidSession]);

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId) && setIntervalId(null);
    };
  }, [intervalId]);

  const handleIcChange = (event) => {
    const { value } = event.target;
    setIC(value);
    if (value && !isValidIDNumber(value)) setIcError(t("kocekIn.NO_SPECIAL_CHARACTERS"));
    else setIcError(null);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const body = {
        ic,
        password,
      };
      const response = await Axios.post(API.KOCEK_IN_LOGIN, body);

      if (response.data && response.data.token) {
        setUserToken(response.data.token);
        navigate("/kocek-in/coin-sorting", { state: { ic } });
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const checkAuthenticationStatus = async (nonceAuth) => {
    try {
      const response = await fetch(`${API.ISTREAM_CHECK_AUTH}?nonce=${nonceAuth}`, {
        method: "GET",
        headers: {
          "x-business-name": `${ISTREAM_BUSINESS_NAME}`,
          "x-api-key": `${ISTREAM_API_KEY}`,
        },
      });
      const data = await response.json();
      if (data.isAuthenticated) {
        setMdidSession({ ic: data.ic, nonce: nonceAuth, name: data.name });
        clearInterval(intervalId);
        setIntervalId(null);
      }
    } catch (error) {
      console.error("Error checking authentication status:", error);
    }
  };

  const loginWithMdId = () => {
    const newNonce = generateNonce();
    setNonce(newNonce);
    clearInterval(intervalId);
    setIntervalId(null);

    const qrString = `{"action": "AUTH_INFO", "url": "${API.ISTREAM_REDIRECT_APP}?nonce=${newNonce}", "nonce": "${newNonce}"}`;
    window.location.href = `misignet://mydigitalid?qr=${btoa(qrString)}`;
    let appOpened = false;

    const checkApp = setTimeout(() => {
      if (!appOpened) {
        window.location.href = /Android/i.test(navigator.userAgent) ? playStoreURL : appStoreURL;
      }
    }, 1500);

    window.addEventListener("blur", () => {
      appOpened = true;
      clearTimeout(checkApp);

      const id = setInterval(() => {
        checkAuthenticationStatus(newNonce);
      }, 3000);
      setIntervalId(id);
    });
  };

  const handleClickRegister = () => {
    navigate("/kocek-in/signup");
    clearInterval(intervalId);
    setIntervalId(null);
    setMdidSession(null);
  };

  const handleMdidSessionLogin = async () => {
    try {
      const response = await Axios.post(API.KOCEK_IN_MDID_LOGIN, {
        ic: mdidSession.ic,
        nonce: mdidSession.nonce,
      });
      if (response.status === 200) {
        setUserToken(response.data.token);
        navigate("/kocek-in/coin-sorting");
      } else {
        navigate("/kocek-in/signup");
      }
    } catch (error) {
      console.error("Error during MDID login:", error);
      navigate("/kocek-in/signup");
    }
  };

  return (
    <MainLayout
      hasFooter={false}
      backButton
      title={t("kocekIn.SIGN_IN")[0] + t("kocekIn.SIGN_IN").substring(1).toLowerCase()}
    >
      <div className="main container">
        <div className="text-center mt-4">
          <h5>{t("kocekIn.WELCOME_BACK_TO_KOCEK")}</h5>

          <button
            type="button"
            className="w-100 mt-4 py-2 border border-1 bg-white my-3"
            style={{ borderColor: "#CCCCC" }}
            onClick={loginWithMdId}
          >
            <img src={myDigitalIDRoundLogo} alt="My Digital ID" className="mx-2" height="50px" />
            {t("kocekIn.LOGIN_WITH")}
          </button>

          <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
            <hr style={{ flexGrow: 1, border: "none", borderTop: "1px solid #575757" }} />
            <span style={{ margin: "0 10px", color: "#ccccc" }}>OR</span>
            <hr style={{ flexGrow: 1, border: "none", borderTop: "1px solid #575757" }} />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-outline mt-4 mb-4 text-start">
              <label htmlFor="nric">{t("kocekIn.NRIC")}</label>
              <input
                value={ic}
                onChange={handleIcChange}
                id="nric"
                className={`form-control mt-2 ${icError ? "border-danger error" : null}`}
                placeholder="123456789012"
              />
              {icError ? <div className="signupTitle1 text-danger mt-2">{icError}</div> : null}
            </div>
            <div className="form-outline mb-4 text-start">
              <label htmlFor="password">{t("kocekIn.PASSWORD")}</label>
              <div className="input-group mt-2">
                <input
                  value={password}
                  onChange={handlePasswordChange}
                  type={isVisible ? null : "password"}
                  id="password"
                  className="border-end-0 form-control"
                  style={{ flex: 1 }}
                />
                <span className="input-group-append">
                  <div
                    className="form-control border-start-0 rounded-0 rounded-end"
                    type="button"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <i
                      className={`fa-solid ${isVisible ? "fa-eye" : "fa-eye-slash"} fa-sm`}
                      style={{ color: "#788494" }}
                    />
                  </div>
                </span>
              </div>
            </div>
            <button
              className={`cstmbtn mb-4 ${ic && password && !icError ? "enabled" : "disabled"}`}
              type="submit"
              disabled={!ic || !password || icError || isLoading}
            >
              {isLoading ? <LoadingSpinner color="#ffffff" borderWidth={2} /> : t("kocekIn.SIGN_IN")}
            </button>
          </form>
          <div className="kocek-sign-up">
            {t("kocekIn.NOT_KOCEK_MEMBER")}?{" "}
            <span className="create-account" onClick={handleClickRegister}>
              {t("kocekIn.CREATE_AN_ACCOUNT")}
            </span>
          </div>
          {/* Div to display the response */}
          <div id="response" className="mt-3" />
        </div>
      </div>
    </MainLayout>
  );
}

export default Login;
