/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import { getDistance, convertToKilometers } from "./components/Geolocation";
import { countries } from "./Constant";
import logo1 from "../../images/kocek_black_logo.png";
import MainLayout from "../../layouts/MainLayout";
import LoadingSpinner from "../../components/LoadingSpinner";
import BottomSheetModal from "../../components/BottomSheet";
import SearchBar from "../../components/SearchBar";
import DropDownSelection from "../../components/DropDownSelection/DropDownSelection";
import API from "../../configs/API";
import "./style.css";
import Axios from "../../configs/serviceProvider/Axios";

function Merchant() {
  const {
    selectedLocation,
    setSelectedLocation,
    setConfirmedOrderDetails,
    setuserDetails,
    setBusinessDetails,
    setSelectedPaymentMethod,
    setSelectedOnlineBankingMethod,
    setMdidSession,
    setUserToken,
  } = useContext(CoinContext);
  const [merchantList, setMerchantList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [searchedState, setSearchedState] = useState("");
  const [openCountryList, setOpenCountryList] = useState(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [openDays, setOpenDays] = useState([]);
  const [isOpen, setIsOpen] = useState({});
  const [showDiv, setShowDiv] = useState(false);
  const [hourStart, setHourStart] = useState(false);
  const [hourEnd, setHourEnd] = useState(false);

  // get location //
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (errors) => {
          setError(errors.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);
  ///

  useEffect(() => {
    const fetchMerchant = async () => {
      setIsLoading(true);
      try {
        let queryString = "";

        if (filterValue || selectedCountry.length > 0 || hourStart || hourEnd) {
          const filters = {
            store_name: filterValue,
            state: selectedCountry,
            startTime: hourStart,
            endTime: hourEnd,
          };

          queryString = `filters=${encodeURIComponent(JSON.stringify(filters))}`;
        }

        const url = `${API.GET_ALL_MERCHANT}?${queryString}`;
        const result = await Axios.get(url);

        const shift = result.data.merchants.map((merchant) => ({
          // id: merchant.id,
          ...merchant,
          open_day: JSON.parse(merchant.open_day),
        }));
        setOpenDays(shift);

        const distances = shift.map((location) => {
          const distance = getDistance(latitude, longitude, location.latitude, location.longitude);
          return { ...location, distance };
        });

        const updatedLocations = distances.map((location) => ({
          details: location,
          distance: convertToKilometers(location.distance),
        }));

        const sortMerchantListByDistance = (locations) => {
          return locations.sort((a, b) => a.distance - b.distance);
        };

        const sortedMerchantList = sortMerchantListByDistance(updatedLocations);

        // Calculate pagination
        let filteredMerchantList = sortedMerchantList;

        if (selectedCountry.length > 0) {
          filteredMerchantList = sortedMerchantList.filter((merchant) => {
            return selectedCountry.includes(merchant.details.state);
          });
        }

        const totalFilteredResults = filteredMerchantList.length;

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedMerchantList = filteredMerchantList.slice(startIndex, endIndex);

        setMerchantList(paginatedMerchantList);
        setTotalPages(
          selectedCountry.length > 0
            ? Math.ceil(totalFilteredResults / pageSize)
            : Math.ceil(sortedMerchantList.length / pageSize)
        );
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setError("Error getting location. Please allow access to your location.");
      }
    };
    if (selectedCountry.length >= 0 && latitude && longitude) {
      // console.log("Fetching data...");
      fetchMerchant();
    }
  }, [currentPage, filterValue, latitude, longitude, selectedCountry, hourStart, hourEnd]);

  // reset useContext to null
  useEffect(() => {
    setSelectedLocation(null);
    setConfirmedOrderDetails([]);
    setuserDetails(null);
    setSelectedPaymentMethod(null);
    setBusinessDetails(null);
    setSelectedOnlineBankingMethod(null);
    setMdidSession(null);
    setUserToken(null);
  }, []);

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    setCurrentPage(1);
  };

  // Open day//
  useEffect(() => {
    const checkWorkingHours = () => {
      const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
      const today = days[new Date().getDay()];

      if (openDays.length === 0) {
        // Handle the case when there are no merchants available
        return;
      }

      const openStatus = {};

      for (const location of openDays) {
        const { id } = location;

        if (location.open_day[today] && location.open_day[today].shifts) {
          const currentDateTime = new Date();
          const currentTime = currentDateTime.getHours() * 60 + currentDateTime.getMinutes(); // Convert current time to minutes
          const { shifts } = location.open_day[today];
          let isOpenNow = false;

          for (const shift of shifts) {
            const startTime = new Date(shift.startTime);
            const endTime = new Date(shift.endTime);

            const shiftStartTime = startTime.getHours() * 60 + startTime.getMinutes(); // Convert shift start time to minutes
            const shiftEndTime = endTime.getHours() * 60 + endTime.getMinutes(); // Convert shift end time to minutes

            if (currentTime >= shiftStartTime && currentTime <= shiftEndTime) {
              isOpenNow = true;
              break;
            }
          }

          openStatus[id] = isOpenNow;
        } else {
          openStatus[id] = false;
        }
      }

      setIsOpen(openStatus);
    };

    checkWorkingHours();
    const interval = setInterval(checkWorkingHours, 60000); // Update every minute
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [openDays]);

  const getClosingTime = (merchant) => {
    const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const today = days[new Date().getDay()]; // Get the current day's abbreviation (e.g., "mon")

    const currentDateTime = new Date();
    const currentTime = currentDateTime.getHours() * 60 + currentDateTime.getMinutes(); // Convert current time to minutes

    if (merchant.open_day[today] && merchant.open_day[today].shifts) {
      const { shifts } = merchant.open_day[today];

      for (const shift of shifts) {
        const startTime = new Date(shift.startTime);
        const endTime = new Date(shift.endTime);

        const shiftStartTime = startTime.getHours() * 60 + startTime.getMinutes(); // Convert shift start time to minutes
        const shiftEndTime = endTime.getHours() * 60 + endTime.getMinutes(); // Convert shift end time to minutes

        if (currentTime >= shiftStartTime && currentTime <= shiftEndTime) {
          // Merchant is currently open, so return the closing time
          return formatTime(endTime);
        }
      }
    }

    // Merchant is closed, return an empty string for closing time
    return "";
  };

  // Function to format time as "HH:MM AM/PM"
  const formatTime = (time) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  //

  const renderMerchantCards = () => {
    // locations
    return merchantList.map((merchant, key) => (
      <div
        key={key}
        onClick={() => isOpen[merchant.details.id] && setSelectedLocation(merchant)}
        className={`card mt-4 ${selectedLocation === merchant && "card-active"} ${
          !isOpen[merchant.details.id] && "card-disabled"
        }`}
      >
        <div className="card-body">
          <div className="text-start tar-left">
            <div className="card-title mb-2 card-merchant">{merchant.details.store_name}</div>
            <div className="card-subtitle mb-2 card-details text-muted">{merchant.details.address}</div>
            <div className="card-subtitle card-details text-muted">
              {merchant.details.is_available === "0" ? (
                <span className="text-danger">Temporarily Closed</span>
              ) : isOpen[merchant.details.id] ? (
                <div>
                  <span className="text-success">{t("OPEN")}</span>
                  <span className="dot" />
                  {t("CLOSE")} {getClosingTime(merchant.details)}
                </div>
              ) : (
                <span className="text-danger">{t("CLOSE")}</span>
              )}
            </div>
          </div>
          <div className="position-absolute top-0 m-2 end-0">
            <span className="card-subtitle card-details">{merchant.distance} KM</span>
          </div>
        </div>
      </div>
    ));
  };

  const renderPagination = () => {
    return (
      <div className="mt-3">
        <div className="row">
          <div
            className={`btn col-5 text-start button-chevron ${currentPage === 1 ? "disable" : "enabled"}`}
            onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          >
            <i className="fas fa-chevron-left" /> {t("PREVIOUS")}
          </div>
          <div className="col-2 text-center paginationNo">
            <span>
              {currentPage}/{totalPages}
            </span>
          </div>
          <div
            className={`btn col-5 text-end button-chevron ${currentPage === totalPages ? "disable" : "enabled"}`}
            onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          >
            {t("NEXT")} <i className="fas fa-chevron-right" />
          </div>
        </div>
      </div>
    );
  };

  const operationHour = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between filter-title pb-4">
          <div className="d-flex align-items-center">
            <div className="text-start" style={{ cursor: "pointer" }} onClick={toggleAccordion}>
              Opening Hour
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="text-end" style={{ cursor: "pointer" }} onClick={toggleAccordion}>
              {showDiv ? "▼" : <>&#8250;</>} {/* Show arrow icon based on open/closed state */}
            </div>
          </div>
        </div>
        {showDiv && (
          <div className="grid-container">
            <div className="left-buttons">
              <button
                type="button"
                className={`button mb-2 ${hourStart === "08:00:00" && hourEnd === "17:00:00" ? "selectedTime" : ""}`}
                onClick={() => handleTime("08:00:00", "17:00:00")}
              >
                08:00AM - 05.00PM
              </button>
              <button
                type="button"
                className={`button mb-2 ${hourStart === "09:00:00" && hourEnd === "20:00:00" ? "selectedTime" : ""}`}
                onClick={() => handleTime("09:00:00", "20:00:00")}
              >
                09:00AM - 08.00PM
              </button>
            </div>
            <div className="right-buttons">
              <button
                type="button"
                className={`button mb-2 ${hourStart === "09:30:00" && hourEnd === "17:00:00" ? "selectedTime" : ""}`}
                onClick={() => handleTime("09:30:00", "17:00:00")}
              >
                09:30AM - 05.00PM
              </button>
              <button
                type="button"
                className={`button mb-2 ${hourStart === "10:30:00" && hourEnd === "20:00:00" ? "selectedTime" : ""}`}
                onClick={() => handleTime("10:30:00", "20:00:00")}
              >
                10:30AM - 08.00PM
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleTime = (start, end) => {
    setHourStart(start);
    setHourEnd(end);
  };

  // Function to toggle the accordion section open/closed
  const toggleAccordion = () => {
    setShowDiv(!showDiv);
  };

  const footerButtonOnClick = () => {
    navigate("/merchant-details");
  };

  const openModal = () => setOpenBottomSheet(true);
  const closeModal = () => setOpenBottomSheet(false);

  const openCountryListModal = () => {
    setOpenCountryList(true);
  };

  const radioOnClick = (event) => {
    setSelectedCountry((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(event)) {
        return prevSelectedOptions.filter((option) => option !== event);
      }
      return [...prevSelectedOptions, event];
    });
  };
  // console.log(selectedCountry);

  const confirmSelected = () => {
    setOpenCountryList(false);
    closeModal();
  };

  const onChangeText = (event) => {
    const searchText = event.toLowerCase();
    const filtered = countries.filter((country) => country.toLowerCase().includes(searchText));
    setFilteredCountries(filtered);
    setSearchedState(searchText);
  };

  return (
    <>
      <BottomSheetModal className="stock-bottom-sheet" openModal={openBottomSheet} onDismiss={closeModal}>
        <div className="px-2 order-details-container border-bottom">
          <div className="d-flex align-items-center justify-content-center position-relative mt-2 pb-3">
            <span className="text-center">{t("FILTER_BY")}</span>
            <span
              className="close-bottom-sheet text-reset"
              onClick={() => {
                setSelectedCountry([]);
                setHourStart(false);
                setHourEnd(false);
              }}
            >
              Reset
            </span>
          </div>
        </div>
        <div className="px-2 mt-2 mb-4">
          {merchantList && merchantList.length > 0 ? (
            <>
              {/* State */}
              <div className="border-bottom">
                <div
                  className="d-flex align-items-center justify-content-between filter-title"
                  style={{ cursor: "pointer" }}
                  onClick={openCountryListModal}
                >
                  <div className="d-flex align-items-center">
                    <div className="text-end">{t("STATES")}</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="text-end">&#8250;</div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="row px-3 py-1">
                    {selectedCountry.map((item) => (
                      <div className="col-auto" key={item}>
                        <button type="button" className="btnFilter">
                          {item}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ borderBottom: "1px ", width: "80%" }} />

              {/* Opening Hour */}
              {operationHour()}
            </>
          ) : (
            <div className="empty-order-container text-center">{/* {t("NO_RECORD_IN_ORDER_DETAILS")} */}</div>
          )}
        </div>

        <div
          className="d-grid"
          style={{
            marginTop: "auto",
            padding: "10px",
          }}
        >
          <button className="cnfrmbtnModal" type="button" onClick={confirmSelected}>
            Confirm
          </button>
        </div>
      </BottomSheetModal>

      {openCountryList && (
        <BottomSheetModal
          openModal={openBottomSheet}
          onDismiss={() => setOpenCountryList(false)}
          maxHeightRatio={0.6}
          minHeightRatio={0.6}
          className="industry-bottom-sheet"
        >
          <div className="px-2 order-details-container border-bottom mb-4">
            <div className="d-flex align-items-center justify-content-center position-relative mt-2 pb-3">
              <span className="text-center">States</span>
              <span className="close-bottom-sheet text-reset" onClick={() => setSelectedCountry([])}>
                Reset
              </span>
              <span className="back-bottom-sheet text-reset" onClick={() => setOpenCountryList(false)}>
                &#8249;
              </span>
            </div>
          </div>
          <SearchBar onChangeText={onChangeText} searchedState={searchedState} placeholder={t("SEARCH_STATE")} />
          <div className="dropdown-selection cstmbdy">
            {filteredCountries.length > 0 ? (
              <div>
                {filteredCountries.map((item) => {
                  return (
                    <DropDownSelection
                      key={item}
                      data={item}
                      name={item}
                      value={item}
                      checked={selectedCountry.includes(item)}
                      radioOnClick={radioOnClick}
                    />
                  );
                })}
              </div>
            ) : (
              <div>No results found</div>
            )}

            <div
              className="d-grid"
              style={{
                marginTop: "auto",
                marginBottom: "10px",
                padding: "10px",
              }}
            >
              <button className="cnfrmbtnModal" type="button" onClick={confirmSelected}>
                {t("CONFIRM")}
              </button>
            </div>
          </div>
        </BottomSheetModal>
      )}

      <MainLayout
        footerText={t("CONTINUE")}
        title={t("KOCEK_OUT_ONLINE_ORDER")}
        hasFooter
        hamburgerButton
        footerButtonOnClick={footerButtonOnClick}
        disabled={!selectedLocation}
        disabledStyle={{
          backgroundColor: "#F5F5F5",
          color: "#30303080",
          borderColor: "#F5F5F5",
          width: "100%",
        }}
      >
        <div className="main container">
          <div className="text-center">
            <img src={logo1} className="App-logo mt-3 mb-2" alt="logo" width="40%" height="auto" />
            <div className="tagline">{t("MERCHANT_SUBTITLE")}</div>

            <div className="mt-4">
              <p className="main-title">{t("MERCHANT_TITLE")}</p>
            </div>

            <div className="row mt-4 bg-white">
              <div className="col-md-5 mx-auto">
                <form>
                  <div className="input-group mb-2">
                    <span className="input-group-text">
                      <i className="fa fa-search" aria-hidden="true" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Dollar amount (with dot and two decimal places)"
                      value={filterValue}
                      onChange={handleFilterChange}
                    />
                  </div>
                </form>
                <div className="card-details text-start d-flex">
                  {t("NUMBER_OF_KIOSK")(merchantList.length)}
                  <button type="button" className="btnFilter ms-auto" onClick={openModal}>
                    {t("FILTER")}
                  </button>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="text-center mt-5">
                <LoadingSpinner color="#C4C4C4" borderWidth={2} />
              </div>
            ) : latitude && longitude ? (
              <div className="col-md-5 mx-auto">
                <div className="row">{renderMerchantCards()}</div>
                {renderPagination()}
              </div>
            ) : (
              <div>{error}</div>
            )}
          </div>
        </div>
      </MainLayout>
    </>
  );
}

export default Merchant;
