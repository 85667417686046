import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import API from "../../configs/API";
import Axios from "../../configs/serviceProvider/Axios";
import { otp, myDigitalIDbannerBM, myDigitalIDbannerEN } from "../../images";

function CoinSorting() {
  const { selectedLocation, selectedLanguage, userToken } = useContext(CoinContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await Axios.get(API.KOCEK_IN_GET_CLIENT, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        if (response.data && response.data.user) {
          setUserDetails(response.data.user);
        }
      } catch (error) {
        toast.error(error);
      }
    };

    getUserDetails();
  }, [userToken, location.state]);

  const targetClick = () => {
    // const url = `https://mydigitalid.dapat.com/tracking?nric=${ic}&channel=kocek`;
    const url = `https://mydigitalid.dapat.com/tracking?fullname=${userDetails.firstName}&phone=${
      userDetails.countryCode + userDetails.phone
    }&channel=kocek&nric=${userDetails.ic}`;
    window.location.href = url;
  };

  return (
    <MainLayout hasFooter={false} title={selectedLocation?.details?.store_name || ""}>
      <div className="main container">
        <div className="iconContainer mt-4 mb-4">
          <img src={otp} className="App-logo" style={{ minWidth: 94 }} alt="logo" width="30%" height="auto" />
        </div>
        <div className="text-center">
          <h5>{t("kocekIn.SORTING_COINS")}</h5>
          <div className="title2 mt-4 text-muted">{t("kocekIn.SORT_TITLE")}</div>
          <div className="title2 mt-4 text-muted">{t("kocekIn.SORT_TITLE_2")}</div>
        </div>
        <button className="cstmbtn mb-4 enabled mt-4" onClick={() => navigate("/insert-code")}>
          {t("kocekIn.INSERT_CODE")}
        </button>
        <div className="iconContainer pt-2">
          <img
            src={selectedLanguage === "en" ? myDigitalIDbannerEN : myDigitalIDbannerBM}
            className="cstmbtn"
            style={{ minWidth: 100, cursor: "pointer" }}
            alt="logo"
            width="100%"
            height="auto"
            onClick={targetClick}
          />
        </div>
      </div>
    </MainLayout>
  );
}

export default CoinSorting;
