export const NODE_ENV = "production";
export const GOOGLE_ANALYTIC_ID = "G-S4ETXQXDZ0";
export const REACT_API_URL = "https://api.kocek.com/"
export const PRTNRID = 'kocek_my'
export const API_ID = '8c6af43a-71b3-11ee-82f2-0c42a1b6ce96'
export const API_KEY = 'uQQKP0mZkYU39XZYebOVo5HlqKMThjlt'
export const JWT_SECRET="cHqdIqtCNc7AlIi2Hk8uYmNThuJ6J8ln"
export const PAYMENT_API_URL = "https://pgw.dapat.com/"
export const MYPAY_API_URL = "https://api-v2.mypay.com.my";
export const ISTREAM_URL = "https://mydvid.dapat.com";
export const ISTREAM_BUSINESS_NAME = "business1";
export const ISTREAM_API_KEY = "d2a716e3beb1ea8269eface3887b380561e12c421e97b7fbb577a993b40e5940";